import React, { Component } from "react";
import { Link } from "react-router-dom";
import viewBtn from "./img/viewBtn.svg";

class Project extends Component {
  constructor(props) {
    super(props);
    this.setActiveProject = this.setActiveProject.bind(this);
  }
  setActiveProject(name) {
    const { setActiveProject } = this.props;
    setActiveProject(name);
  }

  render() {
    return (
      <div
        className="flex flex-column items-center bt"
        style={{ borderColor: "#cfd8dc" }}
        key={this.props.index}
      >
        <div
          className="w-100 ph3-m ph4-ns ph4-l projectRow flex flex-column items-center"
          style={{ maxWidth: "1164px" }}
        >
          <Link
            to={"/" + this.props.project.slug}
            className="projectTitleRow w-100 sticky pt4 pb3 flex items-center space-between"
            onClick={e => {
              this.props.activeProject !== this.props.project.client &&
                this.setActiveProject(this.props.project.client);
            }}
          >
            <div className="fl w-90">
              <div className="projectTitle pr4 pr0-m">
                {this.props.project.client}
              </div>
            </div>
            <div className="fl w-10 h-100 flex items-center justify-end">
              <img src={viewBtn} alt="view button" />
            </div>
          </Link>
        </div>
      </div>
    );
  }
}
export default Project;
