import React, { Component } from "react";
import outBtn from "./img/outBtn.svg";
class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
    };
    this.setActiveProject = this.setActiveProject.bind(this);
  }
  setActiveProject(name) {
    const { setActiveProject } = this.props;
    setActiveProject(name);
  }
  componentWillMount() {
    let project = this.props.projects.filter((proj) => {
      return proj.slug === this.props.proj;
    });
    project = project[0];
    this.setState({
      project: project,
    });
  }
  componentDidMount() {
    window.scroll(0, 0);
    this.setActiveProject(this.state.project.client);
  }

  render() {
    return (
      <div className="w-100 pb3 flex flex-column items-center">
        <div
          className="w-100 ph3-m ph4-ns ph4-l"
          style={{ maxWidth: "1400px" }}
        >
          {this.state.project.heroImage && (
            <div className="pt5">
              <div className="fl w-third">&nbsp;</div>
              <div
                className="fl w-two-thirds"
                style={{ position: "relative" }}
                id="heroImage"
              >
                <img
                  src={this.state.project.heroImage.url}
                  alt={this.state.project.heroImage.description}
                  className="w-100"
                />
                <div className="skrim" />
              </div>
            </div>
          )}
        </div>
        <div
          className="z w-100 ph3-m ph4-ns ph4-l"
          style={{
            maxWidth: "1400px",
            marginTop: "-20px",
          }}
        >
          <div className="fl w-10 dn db-ns">&nbsp;</div>
          <div className="fl w-two-thirds-ns w-100 projectDetailClient">
            {this.state.project.client}
          </div>
        </div>
        <div
          className="z w-100 pt2 ph3-m ph4-ns ph4-l"
          style={{ maxWidth: "1400px" }}
        >
          <div className="fl w-10 dn db-ns">&nbsp;</div>
          <div className="fl w-two-thirds-ns w-100 projectDetailName">
            {this.state.project.name}
          </div>
        </div>
        {this.state.project.background && (
          <div
            className="w-100 pt6 ph3-m ph4-ns ph4-l"
            style={{ maxWidth: "1400px" }}
          >
            <div className="fl w-third dn db-ns">&nbsp;</div>
            <div className="fl z w-two-thirds-ns w-100">
              <h2>Background</h2>
            </div>
          </div>
        )}
        {this.state.project.background &&
          this.state.project.background.map((graph) => {
            if (graph.type === "text") {
              return (
                <div
                  className="processSections w-100 ph3-m ph4-ns ph4-l"
                  style={{ maxWidth: "1400px" }}
                >
                  <div className="fl w-third dn db-ns">&nbsp;</div>
                  <div
                    className="fl z w-two-thirds-ns w-100"
                    dangerouslySetInnerHTML={{ __html: graph.content }}
                  />
                </div>
              );
            } else if (graph.type === "image") {
              return (
                <div className="pb4 pt2">
                  <div
                    className="processSections w-100 pb4 pt2 ph3-m ph4-ns ph4-l"
                    style={{ maxWidth: "1400px" }}
                    dangerouslySetInnerHTML={{ __html: graph.content }}
                  />
                  {graph.description && (
                    <div className="imageDescription">
                      ↑ {graph.description} ↑
                    </div>
                  )}
                </div>
              );
            } else if (graph.type === "video") {
              return (
                <div className="pb4 pt2">
                  <div
                    className="processSections w-100 pb4 pt2 ph3-m ph4-ns ph4-l"
                    style={{ maxWidth: "1400px" }}
                    dangerouslySetInnerHTML={{ __html: graph.content }}
                  />
                  {graph.description && (
                    <div className="imageDescription">
                      ↑ {graph.description} ↑
                    </div>
                  )}
                </div>
              );
            } else {
              return null;
            }
          })}
        {this.state.project.link && (
          <div className="liveProjectLink ba ph3 pv3 mb5">
            <a href={this.state.project.link} target="_about">
              View live &nbsp;
              <span>
                <img src={outBtn} alt="view button" />
              </span>
            </a>
          </div>
        )}
        {/*
        <div
          className="w-100 pt2 ph3-m ph4-ns ph4-l"
          style={{ maxWidth: "1400px" }}
        >
          <div className="fl w-third">&nbsp;</div>
          <div className="fl z w-two-thirds">
            <h2>Goals</h2>
          </div>
        </div>
        {this.state.project.goals &&
          this.state.project.goals.map(graph => {
            if (graph.type === "text") {
              return (
                <div
                  className="goalsSections w-100 ph3-m ph4-ns ph4-l"
                  style={{ maxWidth: "1400px" }}
                >
                  <div className="fl w-third">&nbsp;</div>
                  <div
                    className="fl z w-two-thirds"
                    dangerouslySetInnerHTML={{ __html: graph.content }}
                  />
                </div>
              );
            } else if (graph.type === "image") {
              return (
                <div
                  className="goalsSections w-100 pb4 pt2 ph3-m ph4-ns ph4-l"
                  style={{ maxWidth: "1400px" }}
                  dangerouslySetInnerHTML={{ __html: graph.content }}
                />
              );
            }
          })}*/}
        {this.state.project.myProcess && (
          <div
            className="w-100 pt2 ph3-m ph4-ns ph4-l"
            style={{ maxWidth: "1400px" }}
          >
            <div className="fl w-10">&nbsp;</div>
            <div className="fl z w-two-thirds-ns w-100">
              <h2>My Process</h2>
            </div>
          </div>
        )}
        {this.state.project.myProcess &&
          this.state.project.myProcess.map((graph) => {
            if (graph.type === "text") {
              return (
                <div
                  className="processSections w-100 ph3-m ph4-ns ph4-l"
                  style={{ maxWidth: "1400px" }}
                >
                  <div className="fl w-10">&nbsp;</div>
                  <div
                    className="fl z w-two-thirds-ns w-100"
                    dangerouslySetInnerHTML={{ __html: graph.content }}
                  />
                </div>
              );
            } else if (graph.type === "image") {
              return (
                <div className="pb4 pt2">
                  <div
                    className="processSections w-100 ph3-m ph4-ns ph4-l"
                    style={{ maxWidth: "1400px" }}
                    dangerouslySetInnerHTML={{ __html: graph.content }}
                  />
                  {graph.description && (
                    <div className="imageDescription">
                      ↑ {graph.description} ↑
                    </div>
                  )}
                </div>
              );
            } else if (graph.type === "video") {
              return (
                <div className="pb4 pt2">
                  <div
                    className="processSections w-100 ph3-m ph4-ns ph4-l"
                    style={{ maxWidth: "1400px" }}
                    dangerouslySetInnerHTML={{ __html: graph.content }}
                  />
                  {graph.description && (
                    <div className="imageDescription">
                      ↑ {graph.description} ↑
                    </div>
                  )}
                </div>
              );
            } else {
              return null;
            }
          })}
        {this.state.project.outcomes && (
          <div
            className="w-100 pt2 ph3-m ph4-ns ph4-l"
            style={{ maxWidth: "1400px" }}
          >
            <div className="fl w-third dn db-ns">&nbsp;</div>
            <div className="fl z w-two-thirds-ns w-100">
              <h2>Outcomes</h2>
            </div>
          </div>
        )}
        {this.state.project.outcomes &&
          this.state.project.outcomes.map((graph) => {
            if (graph.type === "text") {
              return (
                <div
                  className="outcomesSections w-100 ph3-m ph4-ns ph4-l"
                  style={{ maxWidth: "1400px" }}
                >
                  <div className="fl w-third dn db-ns">&nbsp;</div>
                  <div
                    className="fl z w-two-thirds-ns w-100"
                    dangerouslySetInnerHTML={{ __html: graph.content }}
                  />
                </div>
              );
            } else if (graph.type === "code") {
              return (
                <div
                  className="outcomesSections w-100 pv3 ph3-m ph4-ns ph4-l"
                  style={{ maxWidth: "1400px" }}
                  dangerouslySetInnerHTML={{ __html: graph.content }}
                />
              );
            } else if (graph.type === "image") {
              return (
                <div className="pb4 pt2">
                  <div
                    className="outcomesSections w-100 ph3-m ph4-ns ph4-l"
                    style={{ maxWidth: "1400px" }}
                    dangerouslySetInnerHTML={{ __html: graph.content }}
                  />
                  {graph.description && (
                    <div className="imageDescription">
                      ↑ {graph.description} ↑
                    </div>
                  )}
                </div>
              );
            } else if (graph.type === "video") {
              return (
                <div className="pb4 pt2">
                  <div
                    className="outcomesSections w-100 ph3-m ph4-ns ph4-l"
                    style={{ maxWidth: "1400px" }}
                    dangerouslySetInnerHTML={{ __html: graph.content }}
                  />
                  {graph.description && (
                    <div className="imageDescription">
                      ↑ {graph.description} ↑
                    </div>
                  )}
                </div>
              );
            } else {
              return null;
            }
          })}
      </div>
    );
  }
}

export default Project;
