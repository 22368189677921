import React, { Component } from "react";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import ReactGA from "react-ga";
import "./tachyons.css";
import "./App.css";
import Loader from "./Loader.js";
import Project from "./Project.js";
import Other from "./Other.js";
import ProjectDetails from "./ProjectDetails.js";
import wordmark from "./img/mf.svg";
import back from "./img/back.svg";
const contentfulUrl =
  "https://cdn.contentful.com/spaces/7952ljvvqsko/environments/master/entries?access_token=ac9a7109b90c65033e91def923fdff8ecb5ec821242610aece684350f8923393&content_type=project&order=fields.order";
const contentfulOthersUrl =
  "https://cdn.contentful.com/spaces/7952ljvvqsko/environments/master/entries?access_token=ac9a7109b90c65033e91def923fdff8ecb5ec821242610aece684350f8923393&content_type=other&order=fields.order";
let projects = [];
let attachments = {};
let others = [];

const grabContentfulProjectData = function(url) {
  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeProject: null,
      loading: true,
    };
    ReactGA.initialize("UA-135683795-1");
    ReactGA.pageview("/");
    this.setActiveProject = this.setActiveProject.bind(this);
  }

  setUpContentful = async () => {
    const dataPR = await grabContentfulProjectData(contentfulUrl);
    const dataOthers = await grabContentfulProjectData(contentfulOthersUrl);
    dataPR.items.forEach((item) => {
      projects.push(item.fields);
    });
    dataOthers.items.forEach((item) => {
      others.push(item.fields);
    });
    dataPR.includes.Asset.forEach((includes) => {
      attachments[includes.sys.id] = {
        url: includes.fields.file.url,
        description: includes.fields.description,
        contentType: includes.fields.file.contentType,
      };
    });
    //Adjusts descriptions data format
    projects.forEach((proj) => {
      if (proj.background) {
        let askContent = [];
        let alt = "";
        proj.background.content.forEach((item) => {
          if (item.nodeType === "paragraph") {
            item.content.forEach((val) => {
              askContent.push({ content: `<p>${val.value}</p>`, type: "text" });
            });
          } else if (item.nodeType === "embedded-asset-block") {
            alt = `alt="${
              attachments[item.data.target.sys.id].description
                ? attachments[item.data.target.sys.id].description
                : ""
            }"`;
            if (
              attachments[item.data.target.sys.id].contentType.includes("image")
            ) {
              askContent.push({
                content: `<img src=${attachments[item.data.target.sys.id].url} ${alt}>`,
                type: "image",
                description: attachments[item.data.target.sys.id].description,
              });
            } else if (
              attachments[item.data.target.sys.id].contentType.includes("video")
            ) {
              askContent.push({
                content: `<video width="100%" autoplay mute loop> <source src=${attachments[item.data.target.sys.id].url} ></video>`,
                type: "video",
                description: attachments[item.data.target.sys.id].description,
              });
            }
          }
        });
        proj.background = askContent;
      }
      if (proj.heroImage) {
        proj.heroImage = {
          url: attachments[proj.heroImage.sys.id].url,
          description: attachments[proj.heroImage.sys.id].description,
        };
      }
      // if (proj.goals) {
      //   let goalsContent = [];
      //   proj.goals.content.forEach(item => {
      //     if (item.nodeType === "paragraph") {
      //       item.content.forEach(val => {
      //         goalsContent.push({
      //           content: `<p>${val.value}</p>`,
      //           type: "text"
      //         });
      //       });
      //     } else if (item.nodeType === "embedded-asset-block") {
      //       goalsContent.push({
      //         content: `<img src=${attachments[item.data.target.sys.id]}>`,
      //         type: "image"
      //       });
      //     }
      //   });
      //   proj.goals = goalsContent;
      // }
      if (proj.myProcess) {
        let processContent = [];
        let alt = "";
        proj.myProcess.content.forEach((item) => {
          if (item.nodeType === "paragraph") {
            item.content.forEach((val) => {
              processContent.push({
                content: `<p>${val.value}</p>`,
                type: "text",
              });
            });
          } else if (item.nodeType === "embedded-asset-block") {
            alt = `alt="${
              attachments[item.data.target.sys.id].description
                ? attachments[item.data.target.sys.id].description
                : ""
            }"`;
            if (
              attachments[item.data.target.sys.id].contentType.includes("image")
            ) {
              processContent.push({
                content: `<img src=${attachments[item.data.target.sys.id].url} ${alt}>`,
                type: "image",
                description: attachments[item.data.target.sys.id].description,
              });
            } else if (
              attachments[item.data.target.sys.id].contentType.includes("video")
            ) {
              processContent.push({
                content: `<video width="100%" autoplay mute loop> <source src=${attachments[item.data.target.sys.id].url}></video>`,
                type: "video",
                description: attachments[item.data.target.sys.id].description,
              });
            }
          } else if (item.nodeType === "heading-3") {
            item.content.forEach((val) => {
              processContent.push({
                content: `<h3>${val.value}</h3>`,
                type: "text",
              });
            });
          }
        });
        proj.myProcess = processContent;
      }
      if (proj.outcomes) {
        let outcomesContent = [];
        let alt = "";
        proj.outcomes.content.forEach((item) => {
          if (item.nodeType === "paragraph") {
            item.content.forEach((val) => {
              if (val.marks.length > 0 && val.marks[0].type === "code") {
                outcomesContent.push({
                  content: `${val.value}`,
                  type: "code",
                });
              } else {
                outcomesContent.push({
                  content: `<p>${val.value}</p>`,
                  type: "text",
                });
              }
            });
          } else if (item.nodeType === "embedded-asset-block") {
            alt = `alt="${
              attachments[item.data.target.sys.id].description
                ? attachments[item.data.target.sys.id].description
                : ""
            }"`;
            if (
              attachments[item.data.target.sys.id].contentType.includes("image")
            ) {
              outcomesContent.push({
                content: `<img src=${attachments[item.data.target.sys.id].url} ${alt}>`,
                type: "image",
                description: attachments[item.data.target.sys.id].description,
              });
            } else if (
              attachments[item.data.target.sys.id].contentType.includes("video")
            ) {
              outcomesContent.push({
                content: `<video width="100%" autoplay mute loop> <source src=${attachments[item.data.target.sys.id].url}></video>`,
                type: "video",
                description: attachments[item.data.target.sys.id].description,
              });
            }
          } else if (item.nodeType === "heading-3") {
            item.content.forEach((val) => {
              outcomesContent.push({
                content: `<h3>${val.value}</h3>`,
                type: "text",
              });
            });
          } else if (item.nodeType === "heading-2") {
            item.content.forEach((val) => {
              outcomesContent.push({
                content: `<h2>${val.value}</h2>`,
                type: "text",
              });
            });
          }
        });
        proj.outcomes = outcomesContent;
      }
    });
    this.setState({
      loading: false,
    });
  };

  setActiveProject(name) {
    if (name === null) {
      this.setState({
        activeProject: null,
      });
    } else {
      this.setState({
        activeProject: name,
        projectScroll: window.scrollY,
      });
      ReactGA.event({
        category: "Project View",
        action: `Viewed ${name}`,
      });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillMount() {
    this.setUpContentful();
  }

  render() {
    if (this.state.loading) {
      return (
        <div id="loader">
          <Loader />
        </div>
      );
    }
    return (
      <Router>
        <div className="App w-100">
          <header>
            {!this.state.activeProject && (
              <div id="wordmark">
                <a href="https://matthewfamularo.com">
                  <img src={wordmark} alt="logo" />
                </a>
              </div>
            )}
            {this.state.activeProject && (
              <NavLink to="/">
                <div
                  id="back"
                  onClick={(e) => {
                    this.setState({
                      activeProject: null,
                    });
                  }}
                >
                  <img src={back} alt="back button" />
                </div>
              </NavLink>
            )}
          </header>
          <Route
            exact
            path="/"
            render={() => (
              <div>
                <div className="fl cf pt6 w-100 tc">
                  <h4>CLIENT WORK</h4>
                </div>
                <div className="cf fl w-100 bb">
                  {projects.map((project, index) => (
                    <Project
                      setActiveProject={this.setActiveProject}
                      project={project}
                      index={index}
                      key={index}
                      {...this.state}
                    />
                  ))}
                </div>
              </div>
            )}
          />
          <Route
            path="/:slug"
            render={({ match }) => (
              <ProjectDetails
                setActiveProject={this.setActiveProject}
                proj={match.params.slug}
                projects={projects}
                key={match.params.slug}
              />
            )}
          />
          {this.state.activeProject === null && (
            <div>
              <div className="fl cf pt5 w-100 tc">
                <h4>OTHER WORK</h4>
              </div>
              <div className="cf fl w-100 bb">
                {others.map((other, index) => (
                  <a href={other.link} target="_about">
                    <Other
                      name={other.name}
                      description={other.description}
                      index={index}
                      key={index}
                    />
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
      </Router>
    );
  }
}

export default App;
