import React, { Component } from "react";

class Loader extends Component {
  render() {
    return (
      <svg viewBox="0 0 1440 510">
        <path
          className="st0"
          d="M19.6,343.5c0,0,22.7-29.1,33.7-38.9c11-9.9,42.9-42.9,54.5-55.1s66.1-66.1,70.8-70.8
c4.6-4.6,78.3-73.7,84.1-80c5.8-6.4,40.6-37.1,49.9-44.1c0,0,41.8-35.4,48.7-37.1s8.7,5.8,4.6,12.8c-4.1,7-173.4,268-186.8,288.9
C165.9,340,72.5,496,72.5,496l145.6-183.9l130.5-157.8l78.9-86.5c0,0,18.6-20.8,23.8-19.6c5.2,1.2,0,19.6,0,19.6l-77.7,157.3
l-36,60.9l-39.4,66.7l-70.2,116c0,0,74.2-102.7,120.6-154.3S475,177,475,177s62.6-56.3,84.1-74.2c21.5-18,39.5-35.3,47.6-35
c8.1,0.2-2.6,20.5-2.6,20.5L495.3,262.8l-87,145c0,0-34.2,53.4-30.7,58.6c3.5,5.2,16.8-0.6,16.8-0.6s27.8-11.2,57.4-29.1
c29.6-17.9,110.2-67.8,140.4-83.5s59.6-33.2,69.6-33.1c10.1,0.2,5.8,17.4,5.8,17.4s-36.2,84.4-29,98.5c9.2,18.1,29.9,18.4,29.9,18.4
s18.2,3.8,71-12.4s158.4-51,158.4-51s61.5-20.3,175.8-49.3s265.1-55.7,350.3-78.9"
        />
      </svg>
    );
  }
}

export default Loader;
