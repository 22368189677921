import React, { Component } from "react";
import outBtn from "./img/outBtn.svg";

class Other extends Component {
  render() {
    return (
      <div
        className="flex flex-column items-center bt"
        style={{ borderColor: "#cfd8dc" }}
        key={this.props.index}
      >
        <div
          className="w-100 ph3-m ph4-ns ph4-l projectRow flex flex-column items-center"
          style={{ maxWidth: "1164px" }}
        >
          <div className="projectTitleRow w-100 sticky pt4 pb4 flex items-center space-between">
            <div className="fl w-90">
              <div className="projectTitle pr4 pr0-m">{this.props.name}</div>
              <div className="p2">{this.props.description}</div>
            </div>
            <div className="fl w-10 h-100 flex items-center justify-end">
              <img src={outBtn} alt="view button" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Other;
